import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Avatar, Button, Divider, List, Modal, Skeleton, Space, Steps, Tabs } from "antd";
import _ from "lodash";
import StickyBox from "react-sticky-box";
import Card from "@/components/Card";
import { IPackageDto, IServiceDto, PackageType, ServiceStatus } from "common/dist/dtos/service/ServiceDto";
import { displayCity, Divide, usePromise } from "@/components/util";
import { ICategoryDto } from "common/dist/dtos/ICategoryDto";
import { ServiceCard } from "@/components/ServiceCard";
import api, { endpoints } from "@/api";
import { IUserInfoDto } from "common/dist/dtos/UserDto";
import { EditOutlined, LikeFilled, MessageOutlined, RightCircleFilled, StarFilled } from "@ant-design/icons";
import { ServiceStore } from "@/common/store/service.store";
import { observer } from "mobx-react";
import { Summary } from "@/components/services/Review";
import { CardCarousel } from "@/components/CardCarousel";
import Link from "next/link";
import { GetServerSidePropsContext } from "next";
import { useSessionContext } from "@/common/session.context";
import { PreviewSection } from "@/components/services/PreviewSection";
import Head from "next/head";
import FilePreview from "@/components/FilePreview";
import Image from "next/image";
import { AffiliateLink } from "@/components/services/AffiliateLink";
import { useMetricsContext } from "@/common/metrics";
import { useRouter } from "next/router";
import { setCookie } from "cookies-next";
import { TopBack } from "@/components/TopBack";
import { currencyFormatterWithTrailing } from "common/dist/util";
import BreadCrumb from "@/components/BreadCrumb";
import { SummaryDto } from "common/dist/dtos/review.dto";
import TabPane = Tabs.TabPane;

interface IPayBoxProps {
    packageDtos: IPackageDto[];
    disablePayment: boolean;
    sellerInfo: IUserInfoDto;
    service: IServiceDto;

    handleCheckout(packageId: number): Promise<void>;
}

function PayBox({ packageDtos, disablePayment, handleCheckout, sellerInfo, service }: IPayBoxProps) {
    const [selectedPackage, setSelectedPackage] = useState<IPackageDto | null>(null);

    useEffect(() => {
        let selectedPackage: IPackageDto | null = null;
        if (packageDtos.length > 2) {
            selectedPackage = packageDtos[2];
        } else if (packageDtos.length > 1) {
            selectedPackage = packageDtos[1];
        } else {
            selectedPackage = packageDtos[0];
        }
        setSelectedPackage(selectedPackage);
    }, [packageDtos]);

    const handlePackageSelection = (pkg: IPackageDto) => {
        setSelectedPackage(pkg);
    };

    const handleCheckoutButtonClick = () => {
        if (selectedPackage) {
            handleCheckout(selectedPackage.id);
        }
    };

    return (
        <div className="shadow-md rounded-md border border-solid bg-white border-gray-200 hover:shadow-xl">
            <div className="p-3">
                <h2 className="text-lg font-semibold mb-2">Service Packages</h2>
            </div>
            <div style={{ padding: "0 16px" }}>
                <Tabs
                    defaultActiveKey={
                        packageDtos.length > 2
                            ? packageDtos[2].type
                            : packageDtos.length > 1
                                ? packageDtos[1].type
                                : packageDtos[0].type
                    }
                    onChange={() => { }}
                    tabBarStyle={{ marginBottom: 0 }}
                    tabBarGutter={8}
                    tabBarExtraContent={null}
                >
                    {packageDtos
                        .slice()
                        .reverse()
                        .map((p) => (
                            <TabPane
                                tab={
                                    <Button
                                        type={selectedPackage && selectedPackage.id === p.id ? "primary" : "default"}
                                        onClick={() => handlePackageSelection(p)}
                                        style={{ borderRadius: "5px" }}
                                    >
                                        {_.capitalize(p.type)}
                                    </Button>
                                }
                                key={p.type}
                            >
                                <div className="flex px-5 pt-5 gap-4 w-72 text-center justify-evenly">
                                    <div>
                                        <p className="text-3xl font-semibold mb-1">
                                            {currencyFormatterWithTrailing.format(p.markupPrice)}
                                        </p>
                                        <small className="small" style={{ color: "#000000" }}>
                                            one time fee
                                        </small>
                                    </div>
                                    <div>
                                        <p className="text-3xl font-semibold mb-1">{p.daysOfDelivery}</p>
                                        <small className="small" style={{ color: "#000000" }}>
                                            days
                                        </small>
                                    </div>
                                </div>
                                <Divider />
                                <div className="px-5">
                                    <div
                                        style={{
                                            wordBreak: "break-word",
                                            color: "#000000"
                                        }}
                                        className="relative small whitespace-pre-wrap text-sm max-h-60 overflow-y-scroll"
                                    >
                                        {p.description}
                                    </div>
                                </div>
                            </TabPane>
                        ))}
                </Tabs>
            </div>
            <div className="p-5">
                {service.status === 'approved' && service.active ? <Button
                    type="primary"
                    size="large"
                    className="w-full"
                    onClick={handleCheckoutButtonClick}
                    disabled={!selectedPackage || disablePayment}
                >
                    Continue to Checkout
                </Button> : <Button
                    type="primary"
                    size="large"
                    className="w-full"
                    disabled={true}
                >Service is currently unavailable</Button>}
                <Link href={`/chat/${sellerInfo.username}`}>
                    <Button type="primary" size="large" className="w-full mt-2" icon={<MessageOutlined />}>
                        Message Seller
                    </Button>
                </Link>
            </div>
        </div>
    );
}

function SmallPayBox({ packageDtos, disablePayment, handleCheckout, sellerInfo, service }: IPayBoxProps) {
    const [packageDto, setPackageDto] = useState<IPackageDto>(packageDtos[0]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setPackageDto(packageDtos[0]);
    }, [packageDtos]);

    return (
        <>
            <Modal
                visible={isModalVisible}
                footer={null}
                title={null}
                closeIcon={<div />}
                onCancel={handleCancel}
                bodyStyle={{ padding: 0 }}
            >
                <PayBox
                    packageDtos={packageDtos}
                    disablePayment={disablePayment}
                    handleCheckout={handleCheckout}
                    sellerInfo={sellerInfo}
                    service={service}
                />
            </Modal>
            <div className="shadow-md rounded-md border border-solid border-gray-200 hover:shadow-xl">
                <div className="flex px-5 pt-2 text-center justify-evenly">
                    <div>
                        <p className="text-2xl font-semibold mb-1">
                            {currencyFormatterWithTrailing.format(packageDto.markupPrice)}
                        </p>
                        <small className="small" style={{ color: "#000000" }}>
                            one time fee
                        </small>
                    </div>
                    <div>
                        <p className="text-2xl font-semibold mb-1">{packageDto.daysOfDelivery}</p>
                        <small className="smal" style={{ color: "#000000" }}>
                            days
                        </small>
                    </div>
                </div>
                <div className="my-2 h-px bg-gray-200"></div>
                <div className="p-2">
                    <Button
                        type="primary"
                        size="large"
                        className="w-full"
                        disabled={disablePayment}
                        onClick={showModal}
                    >
                        See Packages
                    </Button>
                    <div className="mt-2">
                        <Link href={`/chat/${sellerInfo.username}`}>
                            <Button type="primary" size="large" className="w-full" icon={<MessageOutlined />}>
                                Message Seller
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

const Stat = (props: { icon: React.FC; text: React.ReactNode; description: string }) => (
    <span className="inline-flex gap-2 items-center bg-gray-50 rounded-lg px-2  cursor-pointer shadow-sm">
        <span className="text-sm">
            <props.icon />
        </span>
        <span className="text-sm font-semibold">{props.text}</span>
    </span>
);

interface IStandardProps {
    header: string;
    description: any;
    icon: string;
    alt: string;
}

export const vetting: IStandardProps[] = [
    {
        header: "Personality, Language and Reputation",
        description: `The first step in the screening process is a comprehensive English language and communication evaluation. Personality traits are also assessed - we look for freelancers who are passionate and fully engaged in their work. Furthermore, to access an applicant's reputation, we scour the internet to find everything that's publicly available about them.`,
        icon: "/vetting.svg",
        alt: "Our SEO Professionals are vetted for Personality, Language and Reputation."
    },
    {
        header: "In-Depth Skill Review",
        description: `Can you walk the walk or talk the talk? We vet the talent based on results they’ve been able to achieve in past projects. Every freelancer is required to submit previous projects they worked on for internal analysis.`,
        icon: `/transparency.svg`,
        alt: "Our SEO Professionals are vetted through In-Depth Skill Reviews"
    },
    {
        header: "Video Screening",
        description: `Every freelancer is required to submit a 1-minute video presentation about themselves. This allows us to further funnel down our freelance-talent pool and provide you with a transparent network of SEO experts who are willing to go the extra mile.`,
        icon: "/diamond.svg",
        alt: "Our SEO Professionals are vetted through Video Screening"
    },
    {
        header: "Branding and Professionalism",
        description: `We value branding and professionalism as much as we value skills and transparency. We strive to onboard freelancers that understand that there is value in the details. There is always an assessment of freelancers' personal branding capabilities and how they carry themselves.`,
        icon: "/branding.svg",
        alt: "Our SEO Professional are vetted for Branding and Professionalism"
    }
];

export const standards: IStandardProps[] = [
    {
        header: "Verified SEO Experts",
        description: (
            <>
                Our vetting process of {""}
                <Link href="/hire-seo-freelancers">
                    <a className="text-blue-500">SEO experts</a>
                </Link>{" "}
                ensures you receive the highest-quality, highest-value SEO service. We test each SEO service ourselves.
                Less than 5% of applicants are accepted as SEO experts on Vettted.
            </>
        ),
        icon: "/vetting.svg",
        alt: "Hire Verified SEO Experts"
    },
    {
        header: "Full Transparency",
        description: `When hiring an SEO it is as important to know whom you’re buying from as well as what you’re buying. You can review each expert’s case studies, testimonials, and sample work before placing an order.`,
        icon: "/transparency.svg",
        alt: "Full Transparency"
    },
    {
        header: "Customized Services",
        description: (
            <>
                Every SEO professional has their own set of expertise. Vettted lets you choose the experts you want to
                hire. Hire a specialist for {""}
                <Link href="/off-page-seo/backlinks">
                    <a className="text-blue-500">backlinks</a>
                </Link>{" "}
                and another for a {""}
                <Link href="/technical-seo/seo-audit">
                    <a className="text-blue-500">technical audit</a>
                </Link>
                . Customized to meet your business goals.
            </>
        ),
        icon: "/diamond.svg",
        alt: "Hire Vetted Managed search engine optimization services"
    }
];

function Standard(props: IStandardProps) {
    return (
        <div className="bg-indigo-900 text-white p-5 rounded-md flex sm:flex-col gap-5 justify-center sm:justify-start">
            <Image src={props.icon} width={60} height={60} />
            <Space direction="vertical">
                <div className="sm:text-center">{props.header}</div>
                <small>
                    {props.description}
                    {/* <ReadMoreReadLess text={props.description} limit={168} /> */}
                </small>
            </Space>
        </div>
    );
}

function MarketplaceStandards() {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
            {standards.map((s, i) => (
                <Standard {...s} key={i} />
            ))}
        </div>
    );
}

interface IMoreCategoryProps {
    category: ICategoryDto;
}

function MoreCategory({ category }: IMoreCategoryProps) {
    const { loading, data: services = [] } = usePromise(() => endpoints.service.listByCategory(category.id));
    return loading ? (
        <Skeleton active={true} />
    ) : (
        <CardCarousel
            header="Similar SEO Services"
            render={(x) => (
                <ServiceCard size={"normal" /* TODO: "small" service card is not responsive for small yet */} dto={x} />
            )}
            height="410px"
            items={services}
            keyPrefix="category"
            id="more-category"
        />
    );
}

const Reviews = observer(() => {
    const { fetchSummary } = useServiceContext();
    return fetchSummary.match({
        resolved: (s) => <Summary {...s} />,
        pending: () => <Skeleton active={true} />
    });
});

interface IMoreSellerProps {
    sellerId: number;
}

function MoreSeller({ sellerId }: IMoreSellerProps) {
    const { loading, data: services = [] } = usePromise(() => endpoints.service.list(sellerId));
    return loading ? (
        <Skeleton active={true} />
    ) : (
        <CardCarousel
            header="More from the seller"
            render={(x) => <ServiceCard dto={x} />}
            height="410px"
            items={services}
            keyPrefix="seller"
            id="more-seller"
        />
    );
}

interface IMeetSellerProps {
    sellerInfo: IUserInfoDto;
}

function MeetSeller({ sellerInfo }: IMeetSellerProps) {
    return (
        <div className="flex flex-col gap-5">
            <div className="flex gap-5">
                <Link href={`/profile/${sellerInfo.username}`}>
                    <a>
                        <Avatar
                            icon={<Image width={64} height={64} src={sellerInfo.image} objectFit="cover" />}
                            size={64}
                        />
                    </a>
                </Link>
                <div>
                    <h3>{sellerInfo.name || sellerInfo.username}</h3>
                    <small>
                        {sellerInfo.title} | {displayCity(sellerInfo)}
                    </small>
                </div>
            </div>
            <div>
                <Link href={`/chat/${sellerInfo.username}`}>
                    <Button type="primary" icon={<MessageOutlined />}>
                        Message
                    </Button>
                </Link>
            </div>
        </div>
    );
}

interface ISamplesProps {
    urls: string[];
}

function Samples(props: ISamplesProps) {
    return (
        <div>
            <p>See exactly what you're getting before ordering.</p>
            <div className="flex flex-wrap gap-5">
                {props.urls.map((s, i) => (
                    <FilePreview
                        key={i}
                        src={s}
                        width={200}
                        height={150}
                        preview={true}
                        className="object-cover shadow-md rounded-sm border border-solid border-gray-200"
                    />
                ))}
            </div>
        </div>
    );
}

export interface IServicePageProps {
    service: IServiceDto;
    sellerInfo: IUserInfoDto;
    summary: SummaryDto;
}

interface ISectionProps {
    label: string;
    node: React.ReactNode;
}

function Section({ label, node }: ISectionProps) {
    return (
        <div className="flex flex-col gap-5" key={label}>
            <div>
                <h2>{label}</h2>
            </div>
            {node}
        </div>
    );
}

interface IDeliverablesProps {
    deliverables: string[];
}

function Deliverables(props: IDeliverablesProps) {
    return (
        <List split={false}>
            {props.deliverables.map((x, i) => (
                <List.Item key={i}>
                    <Space className="text-base">
                        <RightCircleFilled color="#ff0000" twoToneColor="#ff0000" />
                        {x}
                    </Space>
                </List.Item>
            ))}
        </List>
    );
}

interface IHowItWorksProps {
    steps: string[];
}

function HowItWorks(props: IHowItWorksProps) {
    return (
        <Steps direction="vertical" size="default">
            {props.steps.map((v, i) => (
                <Steps.Step title={v} status="process" key={i} />
            ))}
        </Steps>
    );
}

export const ServiceContext = React.createContext<ServiceStore>(null);
export const useServiceContext = () => useContext(ServiceContext);

export default function ServicePage({ service, sellerInfo, summary }: IServicePageProps) {
    const router = useRouter();
    const metrics = useMetricsContext();
    useEffect(() => {
        metrics.log({ name: "view_item", service_id: `${service.id}` });
    }, [metrics]);

    const session = useSessionContext();
    const store = useMemo(() => new ServiceStore(service), [service]);
    const isSeller = sellerInfo.id === session?.user.userId;
    const hasAffiliate = service.affiliation && typeof window != "undefined" && session;

    const sortedPackages = useMemo(
        () =>
            _.sortBy(service.packages, (p) =>
                [PackageType.Standard, PackageType.Advanced, PackageType.Premium].indexOf(p.type)
            ),
        [service.packages]
    );

    const handleCheckout = useCallback(
        async (packageId: number) => {
            if (!session) {
                setCookie("before_signup", window.location.href);
                await router.push("/login");
                return;
            }
            const cartId = await api.endpoints.cart.create(packageId);
            await router.push(`/checkout/${cartId}`, undefined);
        },
        [router, session]
    );

    const parentCategory = service.parentCategory;

    const breadcrumbItems = [
        { title: parentCategory.name, href: `/${parentCategory.slug}` },
        { title: service.category.name, href: `/${parentCategory.slug}/${service.category.slug}` }
    ];

    return (
        <ServiceContext.Provider value={store}>
            <Head>
                <title>{service.name} | Vettted</title>
                <meta property="og:image" name="og:image" content={service.ogUrl} />
                <meta property="og:url" name="og:url" content={service.url} />
                <meta property="og:title" name="og:title" content={`${service.name} | Vettted`} />
                <meta property="og:description" name="og:description" content="" />
                <meta property="twitter:url" name="twitter:url" content={service.url} />
                <meta property="twitter:title" name="twitter:title" content={`${service.name} | Vettted`} />
                <meta property="twitter:description" name="twitter:description" content="" />
                <meta property="twitter:image" name="twitter:image" content={service.ogUrl} />
                <link rel="canonical" href={service.url} key="canonical" />
                {service.status !== ServiceStatus.Approved && <meta name="robots" content="noindex" />}
            </Head>

            <Card className="p-5 flex flex-col gap-5">
                <BreadCrumb items={breadcrumbItems} />

                <div className="flex items-center">
                    <h1 className="flex-1 w-full font-medium text-2xl sm:text-3xl">{service.name}</h1>
                    {isSeller && (
                        <Link href={`/services/${service.id}/edit`}>
                            <Button shape="circle" icon={<EditOutlined />} />
                        </Link>
                    )}
                </div>
                <div className="flex flex-col gap-5">
                    <div className="flex gap-5">
                        <Link href={`/profile/${sellerInfo.username}`}>
                            <a>
                                <Avatar
                                    icon={<Image width={35} height={35} src={sellerInfo.image} objectFit="cover" />}
                                    size={35}
                                />
                            </a>
                        </Link>
                        <Link href={`/profile/${sellerInfo.username}`}>
                            <a className="text-black hover:text-black">
                                <strong>{sellerInfo.name || sellerInfo.username} </strong>
                            </a>
                        </Link>
                        {summary.purchases >= 10 ? (
                            <div className="flex gap-2 pb-2 flex-wrap">
                                <Stat icon={StarFilled} text={summary.purchases} description="Number of purchases" />
                                <Stat icon={LikeFilled} text={summary.liked} description="Positive reviews" />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="flex gap-5 items-start">
                    <div className="flex flex-col gap-10 flex-1">
                        <PreviewSection
                            thumbnails={service.thumbnails}
                            samples={service.samples}
                            explainerVideoId={service.explainerVideo}
                        />
                        <Divide>
                            <div>
                                <Divide>
                                    {service.steps.length > 0 && (
                                        <Section label="How it Works" node={<HowItWorks steps={service.steps} />} />
                                    )}
                                    <Section
                                        label="About the Service"
                                        node={
                                            <div
                                                style={{
                                                    wordBreak: "break-word"
                                                }}
                                                className="whitespace-pre-wrap"
                                            >
                                                {service.description}
                                            </div>
                                        }
                                    />
                                    {service.deliverables.length > 0 && (
                                        <Section
                                            label="Deliverables"
                                            node={<Deliverables deliverables={service.deliverables} />}
                                        />
                                    )}
                                    <Section label="Samples" node={<Samples urls={service.samples} />} />
                                    <Section label="Reviews" node={<Reviews />} />
                                    <Section label="Meet the seller" node={<MeetSeller sellerInfo={sellerInfo} />} />
                                </Divide>
                            </div>
                        </Divide>
                    </div>
                    <StickyBox
                        offsetTop={20}
                        offsetBottom={20}
                        className="hidden sm:grid justify-center gap-10"
                        style={{ flexBasis: 0 }}
                    >
                        <PayBox
                            packageDtos={sortedPackages}
                            disablePayment={isSeller}
                            handleCheckout={handleCheckout}
                            sellerInfo={sellerInfo}
                            service={service}
                        />
                        {hasAffiliate ? (
                            <AffiliateLink
                                link={`${process.env.DOMAIN}${window.location.pathname}?ref=${session?.user.username}`}
                            />
                        ) : null}
                    </StickyBox>
                </div>
                <div className="sticky bottom-5 sm:hidden bg-white mt-5 z-50">
                    <SmallPayBox
                        packageDtos={sortedPackages}
                        disablePayment={isSeller}
                        handleCheckout={handleCheckout}
                        sellerInfo={sellerInfo}
                        service={service}
                    />
                </div>
                <div className="sm:hidden">
                    {hasAffiliate ? (
                        <AffiliateLink
                            link={`${process.env.DOMAIN}${window.location.pathname}?ref=${session?.user.username}`}
                        />
                    ) : null}
                </div>
                <Divide top={true}>
                    <MoreSeller sellerId={service.sellerId} />
                    <MoreCategory category={service.category} />
                    <MarketplaceStandards />
                </Divide>
                <TopBack />
            </Card>
        </ServiceContext.Provider>
    );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
    const { username, slug } = ctx.params as any;
    const service: any = await api.endpoints.service.getBySlug(username, slug);
    if (!service) {
        return {
            notFound: true
        };
    }
    const summary = await api.endpoints.service.getSummary(service.id);

    const sellerInfo = await api.endpoints.service.getSellerInfo(service.id);
    service["parentCategory"] = await api.endpoints.category.getById(service.category.parentId);
    return {
        props: {
            service,
            sellerInfo,
            summary
        } as IServicePageProps
    };
}
